.alert {
    padding-left: 50px;
    position: relative;
    
    .fa {
        display: flex;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        font-size: 2em;
        width: 50px;
        align-items: center;
        justify-content: center;
    }

    a {
        color: @alert-danger-text;
        font-weight: bold;
    }
}
